import Rails from "rails-ujs";
import qs from "qs";
import safeStartRails from "../common/safe_start_rails";
import { setAutocompleteData } from "./actions";

safeStartRails();

const execSearch = searchtext => {
  return dispatch => {
    let data = qs.stringify({ search: { searchtext } });
    Rails.ajax({
      type: "POST",
      data,
      url: "/cms/query/search_auto_completer.json",
      success: response => {
        let { results } = response;
        //console.log(typeof results);
        
        // get items to ordering
        let order = { "WWW-sivu" : [], "Toimipiste" : [], "Uutinen" : [], "Tapahtuma" : [], "Henkilö" : []},
           titles = ["Sivut", "Toimipisteet", "Uutiset", "Tapahtumat", "Henkilöt"];
        for(let i=0; i < results.length; i++)
        {
          let t = results[i].label.path;
          if( order.hasOwnProperty(t) )
            order[t].push( results[i] );
          else
            order["WWW-sivu"].push( results[i] );
        }
        // set item back to new array an order
        let titleResults = [],
            index = 0;
        for (var key in order) {
          if(order[key].length > 0){
            titleResults.push(  { value: "type"+index, label: { item_type: 'Title', text: titles[index] } } );
            order[key].map( x => titleResults.push(x) );  
          }
          index++;
        }
        //console.log(titleResults);
       
        dispatch(setAutocompleteData(titleResults));
      }
    });
  };
};

export { execSearch };
