const SET_SEARCH_TEXT = "setSearchText",
  SET_AUTOCOMPLETE_DATA = "setAutocompleteData",
  RESET_STATE = "resetState",
  SET_URL = "setUrl";

const setSearchText = searchtext => ({ type: SET_SEARCH_TEXT, searchtext });
const setAutocompleteData = autocomplete_data => ({ type: SET_AUTOCOMPLETE_DATA, autocomplete_data });
const resetState = () => ({ type: RESET_STATE });
const setUrl = url => ({ type: SET_URL, url });
export { SET_SEARCH_TEXT, setSearchText, SET_AUTOCOMPLETE_DATA, setAutocompleteData, resetState, RESET_STATE, setUrl, SET_URL };
