import { SET_LANGUAGE } from "./actions";

const initialLanguage = null;

const languageReducer = (state = initialLanguage, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.language;
    default:
      return state;
  }
};

export { initialLanguage, languageReducer };
