import "core-js/stable";
import "regenerator-runtime/runtime";

import React, { PureComponent } from "react";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";
import Autocomplete from "react-autocomplete";
import { setSearchText, resetState, setUrl } from "./actions";
import { execSearch } from "./functions";
import { setLanguage } from "../language/actions";
import qs from "qs";
import Translate, { translate } from "../common/translate";
import "./styles.scss";

const SearchImg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M23.7,23.1l-4.8-4.8l2.8-9.5l-7.3-7.7L4,3.6L1,13.8l7.3,7.7l9.6-2.4l4.9,4.9L23.7,23.1z M9.2,18.7L4,13.1l2.1-7.4l7.4-1.8l5.2,5.5l-2.1,7.4L9.2,18.7z" />
  </svg>
);

const buildSearchUrl = ({ searchtext, url }) => {
  return url + `?${qs.stringify({ search: { text: searchtext } })}`;
};

const goTo = (dispatch, url) => {
  dispatch(resetState());
  if (url) {
    window.location.href = url;
  }
};

const LoadingSpinner = () => (
  <div
    style={{
      border: "2px solid #f3f3f3",
      borderTop: "2px solid #3498db",
      borderRadius: "50%",
      width: "24px",
      height: "24px",
      animation: "spin 2s linear infinite",
    }}
  />
);

const LoadingIndicator = ({ loading }) =>
  loading ? (
    <span style={{ color: "white", fontWeight: "bold", position: "absolute", top: "22px", right: "15px" }} className="c-autocomplete-loading">
      <LoadingSpinner />
    </span>
  ) : null;

class _AutocompleteField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.state = { placeholder: '' };
    this.state = { language: props.language };
    this.state =  { placeholderArray: translate("search_site", "fi").split('') };
  }
  

  getItemValue = value => {
    let { searchtext } = this.props;
    return value.value === "search" ? searchtext : value.value;
  };

  renderItem = ({ label, value }, isHighlighted) => {
    let { text, path, item_type } = label,
      style = {
        background: isHighlighted ? "rgba(0, 0, 0, 0.10)" : "rgba(255, 255, 255, 0.35)",
      },
      path_type = path ? path.toLowerCase().replace("ä", "a").replace("ö", "o") : "",
      path_content = path ? <span className={`c-autocomplete-item__type ${path_type}`}>{path}</span> : "";

    if (item_type == "Title") {
      return (
        <div className="c-autocomplete-item c-autocomplete-item--title" key={value} style={style} tabIndex="-1">
          <span className="c-autocomplete-item__text">{text} </span>
        </div>
      );
    }

    return (
      <div className="c-autocomplete-item" key={value} style={style} tabIndex="0">
        <span className="c-autocomplete-item__text">{text} </span>
      </div>
    );
  };

  onSelect = value => {
    let { autocomplete_data, dispatch, url, searchtext } = this.props;
    if (value !== searchtext) {
      let { label } = autocomplete_data.find(d => d.value === value) || {};
      url = label.url;
    } else {
      url = buildSearchUrl({ searchtext, url });
    }
    goTo(dispatch, url);
  };

  componentDidMount() {
    this.interval = setInterval(() => {
      let letter = this.state.placeholderArray.shift();
      if(letter === undefined)
      {
        clearInterval(this.interval);
        return;
      }
      this.setState(state => ({
        placeholder: (state.placeholder || '') + letter
      }));
    }, 75);
     
  }

  componentDidUpdate(prevProps) {
    if (prevProps.autocomplete_data !== this.props.autocomplete_data) this.setState({ loading: false });
  }

  onChange = event => {
    let val = event.target.value,
      { dispatch } = this.props;
    dispatch(setSearchText(val));
    if (val.length > 1) {
      this.setState({ loading: true }, () => {
        if (this.ac_timeout) clearTimeout(this.ac_timeout);
        this.ac_timeout = setTimeout(() => {
          dispatch(execSearch(val));
        }, 500);
      });
    }
  };

  render() {
    let { autocomplete_data, searchtext, language, dispatch, url } = this.props,
        { loading } = this.state;

    return (
      <div className="c-autocomplete">
        <Autocomplete
          //open={true}
          value={searchtext || ""}
          items={autocomplete_data}
          renderItem={this.renderItem}
          getItemValue={this.getItemValue}
          onChange={this.onChange}
          onSelect={this.onSelect}
          autoHighlight={false}
          inputProps={{
            id: "search_text",
            className: "form-control",
            onKeyUp: e => {
              if (e.keyCode === 13 && searchtext.length > 0) goTo(dispatch, buildSearchUrl({ searchtext, url }));
            },
            placeholder: this.state.placeholder,
            "aria-label": translate("search_site_label", language),
          }}
        />
        <LoadingIndicator loading={loading} />
      </div>
    );
  }
}

const AutoCompleteField = connect(({ site_search, language }) => ({ ...site_search, language }))(_AutocompleteField);

const SearchButton = () => {
  let dispatch = useDispatch(),
    { searchtext, url, language } = useSelector(({ site_search: ss, language }) => ({ language, searchtext: ss.searchtext, url: ss.url }), shallowEqual),
    search_url = buildSearchUrl({ searchtext, url });
  return (
    <button
      name="button"
      type="submit"
      className="btn btn-outline-success my-2 my-sm-0"
      aria-label={translate("search_button_label", language)}
      onClick={() => goTo(dispatch, search_url)}
      tabIndex="-1"
    >
      <SearchImg />
    </button>
  );
};

class _SiteSearch extends PureComponent {
  componentDidMount() {
    let { language, dispatch, url, searchterm } = this.props;
    dispatch(setLanguage(language));
    dispatch(setUrl(url));
    if (searchterm) dispatch(setSearchText(searchterm));
  }
  render() {
    return (
      <div className="c-searchbox form-inline mt-2 mt-md-0">
        <label htmlFor="search_text" style={{ display: "none" }}>
          <Translate term="search_site" />
        </label>
        <AutoCompleteField />
        <SearchButton />
      </div>
    );
  }
}

const SiteSearch = connect(null)(_SiteSearch);

export default SiteSearch;
