import React from "react";
import ReactDOM from "react-dom";

import siteSearchStore from "../site_search/store";
import { Provider } from "react-redux";
import SiteSearch from "../site_search/components";

document.addEventListener("DOMContentLoaded", () => {
  let container = document.getElementById("site-search");
  if (!container) return;
  let { language, url, searchterm } = container.dataset;
  ReactDOM.render(
    <Provider store={siteSearchStore}>
      <SiteSearch language={language} url={url} searchterm={searchterm} />
    </Provider>,
    container
  );
});
