import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { SET_SEARCH_TEXT, SET_AUTOCOMPLETE_DATA, RESET_STATE, SET_URL } from "./actions";
import { languageReducer } from "../language/reducers";
const loggerMiddleware = createLogger();

const initialState = {
  searchtext: null,
  autocomplete_data: [],
  url: null
};

const siteSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_TEXT:
      return { ...state, searchtext: action.searchtext };
    case SET_AUTOCOMPLETE_DATA:
      return { ...state, autocomplete_data: action.autocomplete_data };
    case RESET_STATE:
      return { ...initialState };
    case SET_URL:
      return { ...state, url: action.url };
    default:
      return state;
  }
};

const siteSearchStore = createStore(
  combineReducers({ site_search: siteSearchReducer, language: languageReducer }),
  applyMiddleware(thunkMiddleware, loggerMiddleware)
);

export default siteSearchStore;
